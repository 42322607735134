
  // 左欄選單
  var menu_trigger = $('.hamburger'),
      overlay = $('.overlay'),
      closed = false;

    menu_trigger.click(function () {
      hamburger_cross();
    });
    overlay.click(function () {
        $('.wrapper').removeClass('toggled');
        $('body').css("overflow", "auto" );

        closed = false;
        menu_trigger.removeClass('is-open');
        menu_trigger.addClass('is-closed');
        overlay.hide();
    });

    function hamburger_cross() {

      if (closed == true) {
        overlay.css("display","none");
        menu_trigger.removeClass('is-open');
        menu_trigger.addClass('is-closed');
        $('body').css("overflow", "auto" );

        closed = false;
      } else {
        $box = $("#myNavbar");
        $time = 300;

        $box.animate({height : '0px'}, $time);
        setTimeout(function () {
          $box.removeClass('in');
        }, $time*2);

        $('body').css("overflow", "hidden" );
        overlay.css("display","block");
        menu_trigger.removeClass('is-closed');
        menu_trigger.addClass('is-open');
        closed = true;
      }
  }
