var swiper = new Swiper('.swiper-aos', {
  navigation: {
    nextEl: '.swiper-button-next-aos',
    prevEl: '.swiper-button-prev-aos',
  },
  pagination: {
    el: '.swiper-pagination-aos',
  },
  autoplay: {
    delay: 5000,
    disableOnInteraction: false,
  },
  on: {
    slideChangeTransitionStart: function () {
      $('.card').children('.cardBox').children('.cardBoxImg').hide(0);
      $('.card').children('.cardBox').children('.cardBoxImg').removeClass('aos-init').removeClass('aos-animate');
    },
    slideChangeTransitionEnd: function () {
      $('.card').children('.cardBox').children('.cardBoxImg').show(0);
      AOS.init();
    },
  }


});

AOS.init();
