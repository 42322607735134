$('.video__ListContent button').click(function(event) {
  $('.video__ListContent').removeClass('videoactive');

  var youTubename = $(this).attr('data-video');
  var allLink = 'https://www.youtube.com/embed/' + youTubename


  var titlename = $(this).attr('title');


  $('.video-containerTitle').children('h3').html(titlename)
  $('.video-container').children('iframe').attr('src',allLink)
  $(this).parent('.video__ListContent').toggleClass('videoactive');
});
