
    $('.video__ListContent a').click(function(event) {
      var youTubename = $(this).attr('data-video');
      var allLink = 'https://www.youtube.com/embed/' + youTubename

      var titlename = $(this).attr('title');


      $('.modalsHead').children('h3').html(titlename)
      $('.modalsBody').children('iframe').attr('src',allLink)

    // 關閉影片聲音
    $("#myVideo").on('hidden.bs.modal', function (e) {
        $("#myVideo iframe").attr("src", $("#myVideo iframe").attr("src"));
    });
  });

    // console.log( "ready!" );

// 顯示最頂
// $("#myModal").on('shown.bs.modal', function (e) {
//   $('#myModal').appendTo("body").modal('show');
// });
